import React from "react";
import PageHeader from "../components/PageHeader";
import TabSection from "../components/TabSection";
import policy from "../data/policy";
const PrivacyPolicy = () => {
  return (
    <>
      {/* page header - start */}
      <PageHeader
        title="Privacy Policy."
        pages={[
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Privacy Policy",
            to: "/privacy-policy",
          },
        ]}
      />
      {/* page header - end */}

      {/* tab section - start */}
      <TabSection data={policy} className="tab-section-1" />
      {/* tab section - end */}
    </>
  );
};

export default PrivacyPolicy;
