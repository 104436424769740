const features = [
  {
    icon: "las la-network-wired",
    heading: "End-to-End AI-Powered Hiring",
  },
  {
    icon: "las la-clipboard-check",
    heading: "Scientifically Validated Assessments",
  },
  {
    icon: "las la-palette",
    heading: "Customizable to Fit Your Needs",
  },
  {
    icon: "las la-magic",
    heading: `Seamless Integration with your ATS`,
  },
  {
    icon: "las la-user-check",
    heading: "Improved Candidate Experience",
  },
  {
    icon: "las la-balance-scale-left",
    heading: "Data-Driven Insights for Informed Decisions",
  },
];

export default features;
