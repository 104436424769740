import React from "react";
// react router dom
import { Routes, Route, useLocation } from "react-router-dom";
// pages
import Header from "./layout/Header";
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./layout/Footer";
import CustomScrollbar from "./components/CustomScrollbar";
import Preloader from "./components/Preloader";
const Markup = () => {
  return (
    <>
      <Preloader />
      <CustomScrollbar />
      <div className="main-wrapper">
        <Header type={useLocation().pathname !== "/" ? "navigation-1" : ""} />
        <Routes>
          <Route path="/" element={<Index />} />
          {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        </Routes>
        <Footer />
      </div>
    </>
  );
};

export default Markup;
