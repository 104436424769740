import React, { useEffect, useRef } from "react";
// components
import DownloadButton from "../components/DownloadButton";
import SectionHeading from "../components/SectionHeading";
import AppFeatureSingle from "../components/AppFeatureSingle";
import ContactForm1 from "../components/ContactForm1";
// images
import hero_phone from "../../assets/svg/first-sec-element.svg";
import artwork from "../../assets/images/first-sec-bg_1.png";
import artwork_left from "../../assets/images/contact_bg_1.png";
import home_screen from "../../assets/images/USER_HOMEPAGE.svg";
import candijet from "../../assets/images/candijet.png";
import interview from "../../assets/images/interview.svg";

// data
import features from "../data/features";
import brand1 from "../../assets/brands/b1.png";
import brand2 from "../../assets/brands/b2.png";
import brand4 from "../../assets/brands/b4.svg";
import brand5 from "../../assets/brands/b5.png";
import brand6 from "../../assets/brands/b6.png";
import brand8 from "../../assets/brands/b8.svg";
import turkcell from "../../assets/brands/turkcell.png";
import cv from "../../assets/images/cv.svg";

const Index = () => {
  const videoEl = useRef(null);

  const handlePlay = () => {
    if (videoEl.current) {
      videoEl.current.muted = false; // Sesi aç
      videoEl.current
        .play()
        .catch((error) =>
          console.error("Error attempting to play the video:", error)
        );
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (videoEl.current) {
              videoEl.current.muted = true; // Başlangıçta sessiz oynatma
              videoEl.current.play().catch((error) => {
                console.error("Error attempting to play the video:", error);
              });
            }
          } else {
            if (videoEl.current) {
              videoEl.current.pause();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoEl.current) {
      observer.observe(videoEl.current);
    }

    // Kullanıcı tıklamasıyla sesi aç ve videoyu oynat
    const handleUserInteraction = () => {
      handlePlay();
      document.removeEventListener("click", handleUserInteraction);
    };

    document.addEventListener("click", handleUserInteraction);

    return () => {
      if (videoEl.current) {
        observer.unobserve(videoEl.current);
      }
      document.removeEventListener("click", handleUserInteraction);
    };
  }, []);
  const playVideo = () => {
    if (videoEl.current) {
      videoEl.current
        .play()
        .catch((error) => console.error("Error attempting to play", error));
    }
  };

  const pauseVideo = () => {
    if (videoEl.current) {
      videoEl.current.pause();
      videoEl.current.muted = true; // Video durduğunda tekrar sessize alınır.
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoEl.current.muted = false;
            playVideo();
          } else {
            pauseVideo();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoEl.current) {
      observer.observe(videoEl.current);
    }

    return () => {
      if (videoEl.current) {
        observer.unobserve(videoEl.current);
      }
    };
  }, []);

  const brands = [turkcell, brand2, brand4, brand5, brand6, brand1];

  return (
    <>
      {/* hero - start */}
      <div className="hero hero-1" id="home">
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                <div className="hero-content">
                  <h1 className="c-dark" style={{ color: "#473AEB" }}>
                    SMART HIRING: <br></br> AI-DRIVEN AND EFFICIENT
                  </h1>
                  <p className="large c-grey">
                    Transform your hiring process with <br></br>{" "}
                    <strong>AI-powered</strong> solutions for a{" "}
                    <strong>smarter</strong>, <strong>faster</strong> <br></br>
                    and <strong>fairer</strong> recruitment experience.
                  </p>

                  <DownloadButton />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                <div className="hero-image">
                  <img className="drop-shadow" src={hero_phone} alt="hero" />
                  <div className="hero-absolute-image">
                    <img src={artwork} alt="artwork" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* video section */}
      <div
        id="video"
        className="feature-section feature-section-0 feature-section-spacing-1"
      >
        <div className="">
          <div className="container">
            <div className="row">
              <iframe
                style={{
                  aspectRatio: "4:3",
                  width: "960px",
                  height: "540px",
                  margin: "0 auto",
                }}
                src="https://www.youtube.com/embed/dUsXjExAyB4?si=zKw0R0m-3NGJKgrc"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      {/* end of video section */}
      {/* Brands start */}
      <div className="brands-wrapper">
        {brands.map((brand, index) => (
          <div key={index} className={index === 3 ? "brand fix" : "brand"}>
            <img src={brand} alt="brand" />
          </div>
        ))}
      </div>
      {/* Brands end */}
      {/* hero - end */}
      {/* app feature - start */}
      <div className="app-feature app-feature-1" id="features">
        <div className="app-feature-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                <SectionHeading
                  subHeading="No human touch."
                  additionalClasses="center "
                />
              </div>
            </div>
            <div className="row gx-5 gy-5">
              {features.map((element, key) => {
                return (
                  <div
                    className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1"
                    key={key}
                  >
                    <AppFeatureSingle
                      icon={element.icon}
                      heading={element.heading}
                      excerpt={element.excerpt}
                      containerClass="app-feature-single-2"
                      link={element.to}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* app feature - end */}
      {/* feature section - start */}
      <div
        id="assessments"
        className="feature-section feature-section-0 feature-section-spacing-1"
      >
        <div className="feature-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 order-lg-1 col-md-12 order-2">
                <div className="feature-section-content">
                  <SectionHeading subHeading="Talent Assessment." />
                  <p
                    className="c-grey"
                    style={{ textAlign: "center", padding: 0 }}
                  >
                    Our adaptive testing technology delivers personalized
                    assessments that accurately measure each candidate's skills
                    and potential. From general aptitude to specialized tests,
                    we cover a wide range of competencies tailored to your
                    needs.
                  </p>
                  <br></br>
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="icon-text"
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <i
                        className="las la-check"
                        style={{ fontSize: "30px" }}
                      ></i>
                      <p className="c-grey" style={{ textAlign: "center" }}>
                        Adaptive Testing adjusts question difficulty in
                        real-time
                      </p>
                    </p>
                    <p
                      className="icon-text"
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <i
                        className="las la-check"
                        style={{ fontSize: "30px" }}
                      ></i>
                      <p className="c-grey" style={{ textAlign: "center" }}>
                        Provides detailed insights and accurate results
                      </p>
                    </p>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="icon-text-group">
                    <div className="icon-text">
                      <i className="las la-universal-access"></i>
                      <h4 className="c-dark">Personal Inventory</h4>
                      <p className="c-grey">
                        Aims to help companies gain a better understanding of
                        the personality and behavior of their job candidates. It
                        covers topics such as work style, motivation,
                        problem-solving, communication skills and much more.
                      </p>
                    </div>
                    <div className="icon-text">
                      <i className="las la-globe"></i>
                      <h4 className="c-dark">English Placement Test</h4>
                      <p className="c-grey">
                        Evaluates a candidate's language proficiency and covers
                        four sections: use of English, listening, speaking, and
                        writing. The use of English and listening sections are
                        smart exams, which means the questions are determined by
                        the system according to the candidate’s answers.
                      </p>
                    </div>
                    <div className="icon-text">
                      <i className="las la-people-carry"></i>
                      <h4 className="c-dark">General Aptitude Test</h4>
                      <p className="c-grey">
                        Measures a candidate's cognitive abilities and potential
                        for success in various fields. It covers topics such as
                        logical reasoning, mathematical skills, spatial
                        awareness and memory.
                      </p>
                    </div>
                  </div>
                  <div className="icon-text-group">
                    <div className="icon-text">
                      <i className="las la-globe"></i>
                      <h4 className="c-dark">German Placement Test</h4>
                      <p className="c-grey">
                        The German Placement Test is designed to evaluate a
                        candidate's proficiency in the German language. It
                        covers four sections: grammar, vocabulary, listening,
                        and speaking.
                      </p>
                    </div>
                    <div className="icon-text">
                      <i className="las la-laptop-code"></i>
                      <h4 className="c-dark">Coding Test</h4>
                      <p className="c-grey">
                        The Coding Test is designed to evaluate a candidate's
                        coding skills and potential for success in a role that
                        requires coding proficiency. It covers areas such as
                        coding logic, problem-solving, and debugging. This test
                        provides an overall assessment of a candidate's coding
                        abilities.
                      </p>
                    </div>
                    <div className="icon-text">
                      <i className="las la-chalkboard-teacher"></i>
                      <h4 className="c-dark">Department-based Tests</h4>
                      <p className="c-grey">
                        Department-based tests are designed to evaluate a
                        candidate's qualifications and potential for success in
                        a specific field such as Sales, IT, Marketing, Finance,
                        HR etc. and can be customized to meet the specific needs
                        of your company.
                      </p>
                    </div>
                  </div>
                  <br />
                  <div
                    className="home_img_cont"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      className="home_img"
                      src={home_screen}
                      style={{
                        maxWidth: "100%",
                        objectFit: "cover",
                        margin: "0 auto",
                      }}
                    />
                  </div>
                  <br />
                  <div id="cv-analysis">
                    <SectionHeading
                      subHeading="CV Analysis Module
"
                    />
                    <p
                      className="c-grey"
                      style={{ textAlign: "center", padding: 0 }}
                    >
                      Streamline your recruitment process with our AI-driven CV
                      Analysis. Simply upload a candidate's CV or LinkedIn
                      profile URL, and our system provides a comprehensive
                      overview of the candidate's qualifications.
                    </p>

                    <div
                      className="list-area"
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          A detailed summary of the candidate's background
                        </p>
                      </p>
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          Categorized interview questions under four main
                          headings: Past Work Experience, Technical Skills,
                          Non-Technical Skills, and Case Analysis
                        </p>
                      </p>
                    </div>
                    <br />
                    <div
                      className="home_img_cont"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="home_img cv"
                        src={cv}
                        style={{
                          maxWidth: "100%",
                          objectFit: "cover",
                          margin: "0 auto",
                        }}
                      />
                    </div>
                    <br />
                  </div>
                  <div id="interview">
                    <SectionHeading
                      subHeading="Interview Analysis Module
"
                    />
                    <p
                      className="c-grey"
                      style={{ textAlign: "center", padding: 0 }}
                    >
                      Our Interview Analysis module transforms how you assess
                      candidates. During the interview, the Hiri bot records and
                      transcribes the conversation.
                    </p>

                    <div
                      className="list-area"
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          A detailed analysis of candidate responses.
                        </p>
                      </p>
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          Identification of strengths, areas for improvement,
                          potential risks, and suitability for the position.
                        </p>
                      </p>
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          An insightful summary report, offering objective
                          data-driven insights into the candidate's fit.
                        </p>
                      </p>
                    </div>
                    <br />
                    <div
                      className="home_img_cont"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="home_img candijet"
                        src={interview}
                        style={{
                          maxWidth: "100%",
                          objectFit: "cover",
                          margin: "0 auto",
                        }}
                      />
                    </div>
                    <br />
                  </div>
                  <div id="candijet">
                    <SectionHeading
                      subHeading="Candijet
"
                    />
                    <p
                      className="c-grey"
                      style={{ textAlign: "center", padding: 0 }}
                    >
                      Designed for high-volume hiring, Candijet simplifies the
                      application process. Candidates scan a QR code, answer
                      three quick video questions, and our system converts their
                      responses into a standardized application form.
                    </p>

                    <div
                      className="list-area"
                      style={{
                        margin: "20px 0",
                      }}
                    >
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          QR code-enabled quick applications
                        </p>
                      </p>
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          Video question responses transcribed and analyzed
                        </p>
                      </p>
                      <p
                        className="icon-text"
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        <i
                          className="las la-check"
                          style={{ fontSize: "30px" }}
                        ></i>
                        <p className="c-grey">
                          Efficient integration into your ATS
                        </p>
                      </p>
                    </div>
                    <br />
                    <div
                      className="home_img_cont"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="home_img candijet"
                        src={candijet}
                        style={{
                          maxWidth: "100%",
                          objectFit: "cover",
                          margin: "0 auto",
                        }}
                      />
                    </div>
                    <br />
                  </div>
                  <SectionHeading
                    subHeading="Behind The Assessments.
"
                  />
                  <p
                    className="c-grey"
                    style={{ textAlign: "center", padding: 0 }}
                  >
                    We are proud to partner with Boğaziçi University Adaptive
                    Test Lab, a leading academic institution in Turkiye, to
                    offer our customers the most accurate and reliable computer
                    adaptive tests. Our assessments are designed by expert
                    psychologists and are based on the latest research in
                    cognitive psychology and educational measurement. Through
                    our partnership with Boğaziçi University Adaptive Test Lab,
                    we are able to offer our users access to cutting-edge
                    assessment technology and expertise.
                  </p>
                  <br />
                  <div
                    className=""
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "40px",
                    }}
                  >
                    <img
                      src={brand8}
                      alt="brand"
                      style={{ maxWidth: "100%", objectFit: "contain" }}
                    />
                  </div>
                  <SectionHeading subHeading="CAT: Revolutionized Testing." />
                  <p
                    className="c-grey"
                    style={{ textAlign: "center", padding: 0 }}
                  >
                    Computer Adaptive Testing (CAT) is an advanced testing
                    method that uses computer technology to optimize the
                    assessment process. Unlike traditional tests where every
                    test-taker receives the same set of questions, a CAT exam
                    adapts to the test-taker's performance by selecting
                    questions of appropriate difficulty level based on their
                    previous responses. The result is a customized test
                    experience for each individual, with questions that are
                    tailored to their abilities.
                  </p>
                  <br />
                  <SectionHeading subHeading="Sustainability at Hiri" />
                  <p
                    className="c-grey"
                    style={{ textAlign: "center", padding: 0 }}
                  >
                    Hiri is committed to sustainability and supporting our
                    customers' sustainability policies. We prioritize reducing
                    our carbon footprint and promoting diversity and ethical
                    business practices in our assessments. Our tests include
                    questions and scenarios that reflect these values, ensuring
                    that we are preparing the next generation of professionals
                    to meet the challenges of a rapidly changing world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* feature section - end */}
      {/* contact form section - start */}
      <div className="contact-form-section contact-form-section-1" id="contact">
        <div className="contact-form-section-wrapper">
          <div className="container">
            <div className="row contact-form-section-row justify-content-center">
              <div className="col-lg-7 offset-lg-0 col-md-8 col-10">
                {/* contact form - start */}
                <ContactForm1 />
                {/* contact form - end */}
              </div>
            </div>
          </div>
          <div className="contact-form-section-pattern">
            <div
              className="center"
              style={{
                backgroundImage: `url(${artwork_left})`,
                height: "calc(100% - 100px)",
              }}
            ></div>
          </div>
        </div>
      </div>
      {/* contact form section - end */}
    </>
  );
};

export default Index;
