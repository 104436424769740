import React from "react";
import { Link } from "react-router-dom";

const LoginButtonHeader = ({ groupType, buttonType }) => {
  return (
    <div
      className={`download-button-group ${groupType}`}
      style={{ display: "inline" }}
      onClick={(e) => {
        window.open("https://assessment.hiri.ai/");
      }}
    >
      <a
        style={{ cursor: "pointer" }}
        onClick={(e) => e.preventDefault()}
        className={`download-button download-button-apple ${buttonType}`}
      >
        <div
          className="download-button-inner"
          style={{ width: "120px", padding: "6px 10px" }}
        >
          {/* <a href="https://assessment.hiri.ai/" target={"_"}> */}
          <div className="download-button-content">
            <h3 className="c-dark ls-2">Login</h3>
          </div>
        </div>
      </a>
    </div>
  );
};

export default LoginButtonHeader;
