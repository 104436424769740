const policy = [
  {
    icon: "las la-file-invoice",
    title: "Terms of Service",
    content: (
      <>
        <h3 className="c-dark">
          Artificial Intelligence Supported Candidate Evaluation Platform User
          Agreement
        </h3>

        <h4> Article 1. Parties</h4>
        <p>
          a) This Artificial Intelligence Supported Candidate Evaluation
          Platform User Agreement ("Agreement"), With AFY Danışmanlık Limited
          Şirketi ("Hiringloop"), which carries out the activities of the
          website www.hiringloop.ai
        </p>
        <p>
          b) It has been concluded between the user ("Company") who is a member
          of the www.hiringloop.ai website and approves this Agreement.
        </p>

        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <h3 className="c-dark">How does Orions collect data about me?</h3>
        <p>Orions colelcts data about you:</p>
        <ul className="icon-list icon-list-1">
          <li>
            <span>When you browse Orions.com.</span>
          </li>
          <li>
            <span>
              When you use Orionss content delivery networks, or visit websites
              that do.
            </span>
          </li>
          <li>
            <span>When you create and use an account on Orions.com.</span>
          </li>
          <li>
            <span>
              When you buy products and services from Orions through the
              Orions.com web store.
            </span>
          </li>
          <li>
            <span>
              When you contact Font Awesome by e-mail for support or other help.
            </span>
          </li>
          <li>
            <span>
              Orions does not buy or otherwise receive data about you from data
              brokers.
            </span>
          </li>
        </ul>
        <p>
          Orions does not buy or otherwise receive data about you from data
          brokers.
        </p>
        <h3 className="c-dark">
          What data does Orions collect about me, and why?
        </h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Optimize the website, so it's quick and easy to use.</span>
          </li>
          <li>
            <span>Diagnose and debug technical errors.</span>
          </li>
          <li>
            <span>Defend the website from abuse and technical attacks.</span>
          </li>
          <li>
            <span>
              Associate your browsing session with an affiliate, through an
              affiliate's referral link.
            </span>
          </li>
          <li>
            <span>
              Associate your account with an affiliate, if you buy after
              clicking through.
            </span>
          </li>
          <li>
            <span>Compile statistics on icon popularity.</span>
          </li>
          <li>
            <span>
              Compile statistics on the kinds of software and computers visitors
              use.
            </span>
          </li>
        </ul>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <h3 className="c-dark">
          How can I make choices about data collection?
        </h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Optimize the website, so it's quick and easy to use.</span>
          </li>
          <li>
            <span>Diagnose and debug technical errors.</span>
          </li>
          <li>
            <span>Defend the website from abuse and technical attacks.</span>
          </li>
          <li>
            <span>
              Associate your browsing session with an affiliate, through an
              affiliate's referral link.
            </span>
          </li>
          <li>
            <span>
              Associate your account with an affiliate, if you buy after
              clicking through.
            </span>
          </li>
          <li>
            <span>Compile statistics on icon popularity.</span>
          </li>
          <li>
            <span>
              Compile statistics on the kinds of software and computers visitors
              use.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">How can I find out about changes?</h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
      </>
    ),
  },
  {
    icon: "las la-question-circle",
    title: "Privacy Policy",
    content: (
      <>
        <p className="paragraph-big">Last updated on May 25, 2021</p>
        <h3 className="c-dark">What is Orions?</h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <h3 className="c-dark">How does Orions collect data about me?</h3>
        <p>Orions colelcts data about you:</p>
        <ul className="icon-list icon-list-1">
          <li>
            <span>When you browse Orions.com.</span>
          </li>
          <li>
            <span>
              When you use Orionss content delivery networks, or visit websites
              that do.
            </span>
          </li>
          <li>
            <span>When you create and use an account on Orions.com.</span>
          </li>
          <li>
            <span>
              When you buy products and services from Orions through the
              Orions.com web store.
            </span>
          </li>
          <li>
            <span>
              When you contact Font Awesome by e-mail for support or other help.
            </span>
          </li>
          <li>
            <span>
              Orions does not buy or otherwise receive data about you from data
              brokers.
            </span>
          </li>
        </ul>
        <p>
          Orions does not buy or otherwise receive data about you from data
          brokers.
        </p>
        <h3 className="c-dark">
          What data does Orions collect about me, and why?
        </h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Optimize the website, so it's quick and easy to use.</span>
          </li>
          <li>
            <span>Diagnose and debug technical errors.</span>
          </li>
          <li>
            <span>Defend the website from abuse and technical attacks.</span>
          </li>
          <li>
            <span>
              Associate your browsing session with an affiliate, through an
              affiliate's referral link.
            </span>
          </li>
          <li>
            <span>
              Associate your account with an affiliate, if you buy after
              clicking through.
            </span>
          </li>
          <li>
            <span>Compile statistics on icon popularity.</span>
          </li>
          <li>
            <span>
              Compile statistics on the kinds of software and computers visitors
              use.
            </span>
          </li>
        </ul>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <h3 className="c-dark">
          How can I make choices about data collection?
        </h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
        <ul className="icon-list icon-list-2">
          <li>
            <span>Optimize the website, so it's quick and easy to use.</span>
          </li>
          <li>
            <span>Diagnose and debug technical errors.</span>
          </li>
          <li>
            <span>Defend the website from abuse and technical attacks.</span>
          </li>
          <li>
            <span>
              Associate your browsing session with an affiliate, through an
              affiliate's referral link.
            </span>
          </li>
          <li>
            <span>
              Associate your account with an affiliate, if you buy after
              clicking through.
            </span>
          </li>
          <li>
            <span>Compile statistics on icon popularity.</span>
          </li>
          <li>
            <span>
              Compile statistics on the kinds of software and computers visitors
              use.
            </span>
          </li>
        </ul>
        <h3 className="c-dark">How can I find out about changes?</h3>
        <p>
          There grass said shall won't thing moved shall sea all male two is.
          Fowl man sixth gathering heaven was brought air fill dry further
          abundantly them be from won't every have cattle darkness moveth every
          the darkness may which evening she'd thing own man is there said
          gathering be the in lesser called.
        </p>
        <p>
          Our, that subdue open above creature i forth make and third beast
          bring had creeping stars brought together. Creepeth creature by open
          us our appear creeping bring they're darkness may which evening she'd
          thing days.
        </p>
      </>
    ),
  },
];

export default policy;
