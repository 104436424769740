import React from "react";

import { Link } from "react-router-dom";

// images
import logo from "../../assets/svg/LOGO_HIRI.svg";

const Footer = () => {
  const links = [
    {
      id: 1,
      text: "Home",
      url: "#home",
    },
    {
      id: 2,
      text: "Features",
      url: "#features",
    },
    {
      id: 3,
      text: "Assessments",
      url: "#assessments",
    },
    {
      id: 4,
      text: "CV Analysis",
      url: "#cv-analysis",
    },
    {
      id: 5,
      text: "Interview Analysis",
      url: "#interview",
    },

    {
      id: 7,
      text: "Candijet",
      url: "#candijet",
    },
    {
      id: 7,
      text: "Contact",
      url: "#contact",
    },
  ];

  const handleClick = (e, url) => {
    e.preventDefault();
    const el = document.getElementById(url.replace("#", ""));
    console.log(e.target);
    console.log(el);
    el &&
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
              <div className="footer-row">
                <div className="footer-detail">
                  <Link to="#">
                    <img src={logo} className="footer-logo" alt="footer-logo" />
                  </Link>
                  <div className="footer-copyright c-grey-1">
                    <h6>&copy; hiri.ai 2024</h6>
                  </div>
                </div>
                <div className="bottom-nav">
                  <ul>
                    {links.map((link) => (
                      <li
                        key={link.text}
                        onClick={(e) => handleClick(e, link.url)}
                      >
                        <a
                          href={link.url}
                          className="link-underline link-underline-1"
                        >
                          <span>{link.text}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
