import React from "react";

const DownloadButton = ({ groupType, buttonType }) => {
  const handleClick = (e, url) => {
    e.preventDefault();
    const el = document.getElementById(url.replace("#", ""));
    console.log(e.target);
    console.log(el);
    el &&
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  return (
    <div
      className={`download-button-group ${groupType}`}
      onClick={(e) => {
        handleClick(e, "#contact");
      }}
    >
      <a
        href="#!"
        className={`download-button download-button-google ${buttonType}`}
      >
        <div className="download-button-inner" style={{ paddingX: "20px" }}>
          <div className="download-button-content">
            <h3 className="c-dark ls-2">Request a Demo</h3>
          </div>
        </div>
      </a>
    </div>
  );
};

export default DownloadButton;
